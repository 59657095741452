import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql, navigate } from 'gatsby'

// Components
import SEO from '../components/SEO'
import withContext from '../lib/withContext'

function Single({ data }) {
  const { edges } = data.allContentfulIconSet

  const metaData = {
    title: `Categories | ${edges[0].node.title}`,
    image: `https://jollycons.com/meta/${edges[0].node.slug}/meta-img-${edges[0].node.slug}.png`
  }

  useEffect(() => {
    navigate(`/categories/${edges[0].node.slug}`)
  })

  return (
    <div>
      <SEO metaData={metaData} />
      Redirect to /categories
    </div>
  )
}

Single.propTypes = {
  data: PropTypes.any,
}

export default withContext(Single)

export const iconSetQuery = graphql`
  query iconSet($slug: String!) {
    allContentfulIconSet(filter: {
      slug: {
        eq: $slug
      }
    }) {
      edges {
        node {
          id
          metaTitle
          metaDescription
          slug
          title
          price
          description {
            id
            description
          }
          relatedSets {
            id
            title
            price
            slug,
            comingSoon
          }
          relatedSetsColors
        }
      }
    }
  }
`
